<template>
  <v-card class="mb-4">
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />

      <v-dialog v-model="createDialog" width="80%" scrollable>
        <template v-slot:activator="{ on }">
          <v-tooltip top v-on="on">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="createDialog = true"
                :disabled="loading || createDialog"
                icon
              >
                <v-icon>mdi-account-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add customer") }}</span>
          </v-tooltip>
        </template>
        <create-client-customer
          @created="
            createDialog = false;
            readCustomers();
          "
          @canceled="createDialog = false"
        />
      </v-dialog>
    </v-toolbar>
    <v-card-subtitle>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
            @click:append-outer="readCustomers"
            @click:clear="clearSeach"
            @keyup.enter.native="readCustomers"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="customers"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="number"
        sort-desc
        :footer-props="{
          itemsPerPageOptions: [15, 50, -1],
        }"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'reseller-products',
                    params: { customerId: item.id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("show customer products") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";
import CreateClientCustomer from "@/components/reseller/CreateClientCustomer";

export default {
  name: "ResellerCustomers",
  mixins: [isMobile],
  components: {
    CreateClientCustomer,
  },
  data: () => ({
    createDialog: false,
    customers: [],
    search: "",
    loading: false,
  }),
  watch: {
    "$store.state.session": function () {
      this.customers = [];
      this.readCustomers();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("nr."),
          value: "number",
        },
        {
          text: this.$i18n.t("name"),
          value: "name",
        },
        {
          text: this.$i18n.t("number products"),
          align: "right",
          value: "number_products",
        },
        {
          text: this.$i18n.t("active products"),
          align: "right",
          value: "number_active_products",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  methods: {
    clearSeach() {
      this.search = "";
      this.readCustomers();
    },
    readCustomers() {
      var that = this;
      this.loading = true;
      this.$http
        .get("customers", {
          params: { q: this.search, limit: 0 },
        })
        .then((response) => {
          that.customers = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.readCustomers();
  },
};
</script>

<i18n>
{
  "en": {
    "add customer": "add customer",
    "search": "Search",
    "nr.": "Nr.",
    "name": "Name",
    "number products": "Number products",
    "active products": "Active products",
    "show customer products": "Show customer products"
  },
  "de": {
    "add customer": "Kunden anlegen",
    "search": "suchen",
    "nr.": "Nr.",
    "name": "Name",
    "number products": "Anzahl Produkte",
    "active products": "aktive Produkte",
    "show customer products": "zeige Kundenprodukte"
  }
}
</i18n>
