<template>
  <validation-observer ref="obs" v-slot="{ errors, invalid }" slim>
    <v-card>
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("create new customer") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-card outlined class="mt-2">
          <v-card-text>
            <address-names
              v-model="address"
              company-name="company_name"
              company-max-length="100"
              first-name-max-length="30"
              last-name-max-length="30"
              :name-prefix="'address.'"
            />
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-card-text>
            <address-postal
              v-model="address"
              additional-max-length="255"
              zip-name="zip_code"
              zip-max-length="10"
              city-max-length="100"
              street-max-length="100"
              street-mumber-max-length="10"
              :name-prefix="'address.'"
            />
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-card-text>
            <address-contacts
              v-model="address"
              phone-max-length="16"
              mobile-max-length="16"
              fax-max-length="16"
              :name-prefix="'address.'"
              email-multiple
              email-max-length="100"
              email-required
            />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="loading"
          @click="
            $emit('canceled');
            address = { ...emptyAddress };
          "
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="loading || invalid"
          :loading="loading"
          @click="createCustomer"
          text
        >
          {{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import AddressNames from "@/components/basics/AddressNames";
import AddressPostal from "@/components/basics/AddressPostal";
import AddressContacts from "@/components/basics/AddressContacts";
import showErrors from "@/utils/mixins/showErrors";

var emptyAddress = {
  company_name: null,
  first_name: null,
  last_name: null,
  salutation: null,
  additional: null,
  street: null,
  street_number: null,
  zip_code: null,
  city: null,
  country: "CH",
  phone: null,
  mobile: null,
  fax: null,
  email: null,
};

export default {
  name: "CreateClientCustomer",
  mixins: [showErrors],
  components: {
    AddressNames,
    AddressPostal,
    AddressContacts,
  },
  data: () => ({
    loading: false,
    emptyAddress: emptyAddress,
    address: { ...emptyAddress },
  }),
  computed: {},
  methods: {
    createCustomer() {
      var that = this;
      this.loading = true;
      this.$http
        .post("customers", { address: this.address })
        .then((response) => {
          that.$emit("created", response.data);
          that.address = { ...emptyAddress };
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {},
};
</script>

<i18n>
{
  "en": {
    "create new customer": "Create new customer settings",
    "create":"Create",
    "cancel": "Cancel"
  },
  "de": {
    "create new customer": "Neuen Kunden anlegen",
    "create":"Anlegen",
    "cancel": "Abbrechen"
  }
}
</i18n>
