var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-4"},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"width":"80%","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-tooltip',_vm._g({attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading || _vm.createDialog,"icon":""},on:{"click":function($event){_vm.createDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1)]}}],null,true)},on),[_c('span',[_vm._v(_vm._s(_vm.$t("add customer")))])])]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('create-client-customer',{on:{"created":function($event){_vm.createDialog = false;
          _vm.readCustomers();},"canceled":function($event){_vm.createDialog = false}}})],1)],1),_c('v-card-subtitle',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"click:append-outer":_vm.readCustomers,"click:clear":_vm.clearSeach},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.readCustomers.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"number","sort-desc":"","footer-props":{
        itemsPerPageOptions: [15, 50, -1],
      }},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'reseller-products',
                  params: { customerId: item.id },
                })}}},on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("show customer products")))])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }